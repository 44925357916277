import React from "react";
import PropTypes from "prop-types";

const Navs = ({ scrollToSection, section1Ref, section2Ref }) => {
  return (
    <div className="">
      <div
        className=" pt-lg-5 pe-lg-5 px-lg-5 pe-3 px-3 pt-3"
        style={{ zIndex: 2 }}
      >
        <div
          className="container-fluid p-lg-4 p-3"
          style={{
            backgroundColor: "orange",
            borderRadius: "20px 20px 0px 0px",
          }}
        >
          <div className="row">
            <div className="col-lg-4 text-center">
              <a className=" p-0 " href="#" style={{ outline: "none" }}>
                <img
                  className="ml-lg-5 animated slow slideInDown "
                  src="/logo.png"
                  alt="Code"
                  style={{ height: "90px" }}
                />
              </a>
            </div>

            <div
              className=" col-lg-8 nav-menulink  text-center d-none-md d-block-lg"
              style={{ flexGrow: "0" }}
            >
              <ul className=" mt-3  mb-lg-0 ">
                <li className="">
                  <span
                    onClick={() => scrollToSection(section1Ref)}
                    className="nav-link  animated slideInDown  slow "
                    aria-current="page"
                  >
                    About
                  </span>
                </li>

                <li className="">
                  <span
                    onClick={() => scrollToSection(section2Ref)}
                    className="nav-link animated slideInUp  slow"
                    aria-current="page"
                  >
                    Tokenomics
                  </span>
                </li>
                <li className="">
                  <a
                    href="https://medium.com/@degens_29040"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" nav-link  animated slow  slideInRight"
                  >
                    Blog
                  </a>
                </li>
                <li className="">
                  <a
                    href="https://rewards.codetoken.vip/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" nav-link  animated slow  slideInRight"
                  >
                    Reward
                  </a>
                </li>
              </ul>
              <div className="d-block d-lg-none"></div>
              <a
                href=" https://twitter.com/codememetoken"
                target="_blank"
                rel="noopener noreferrer"
                className="btn nav-btn animated slow  slideInRight"
              >
                X
              </a>
              <a
                aria-current="page"
                href=" https://t.me/communityofdegens"
                target="_blank"
                rel="noopener noreferrer"
                className="btn nav-btn animated slow  slideInRight"
              >
                TG
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Navs.propTypes = {
  scrollToSection: PropTypes.func.isRequired, // Make sure it's a function and required
  section1Ref: PropTypes.object.isRequired, // Make sure it's an object and required
  section2Ref: PropTypes.object.isRequired, // Make sure it's an object and required
};

export default Navs;
