import React, { Component } from "react";
import Footer from "../components/footer";
import Navs from "../components/navs";
import Modal from "../components/modal";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false, // Initialize showModal to true
    };

    this.section1Ref = React.createRef();
    this.section2Ref = React.createRef();
  }

  scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  render() {
    const popupMessage = (
      <div>
        <img
          src="/pledge.png"
          style={{ width: "140%", height: "90vh", marginLeft: "-20%" }}
        />
      </div>
    );

    return (
      <div style={{ backgroundColor: "#7a0069" }}>
        <Navs
          scrollToSection={this.scrollToSection}
          section1Ref={this.section1Ref}
          section2Ref={this.section2Ref}
        />
        <div className="pe-3 px-3 pe-lg-5 px-lg-5 ">
          <div
            className="container-fluid w3-display-container"
            style={{
              backgroundColor: "orange",
              marginTop: "-10px",
              minHeight: "500px",
              borderRadius: "0px 0px 20px 20px",
              overflow: "hidden",
            }}
          >
            <div>
              <img
                className="ml-lg-5 animated slow w3-display-left d-none d-md-block d-ld-block  zoomIn "
                src="/codeText.png"
                alt="Code"
                style={{
                  width: "600px",
                  zIndex: "1",
                }}
              />
              <img
                className="ml-lg-5 animated slow w3-display-middle  d-block d-md-none d-ld-none   zoomIn "
                src="/iconCode.png"
                alt="Code"
                style={{
                  width: "100%",
                  zIndex: "1",
                }}
              />

              <img
                className="ml-lg-5 animated slow w3-display-right  slideInDown   d-none d-md-block"
                src="/iconCode.png"
                alt="Code"
                style={{
                  width: "300px",
                  zIndex: "1",
                  top: "30%",
                  right: "11%",
                }}
              />
              <img
                className="ml-lg-5 animated slow w3-display-bottomright  slideInRight "
                src="/solana.png"
                alt="Code"
                style={{
                  width: "100px",
                  zIndex: "1",
                  top: "75%",
                  right: "6%",
                }}
              />

              <img
                className="ml-lg-5 animated slow w3-display-bottomright  slideInUp "
                src="/ground_jet.png"
                alt="Code"
                style={{ width: "90vw" }}
              />
            </div>
          </div>
        </div>

        <div className="pe-3 px-3 pe-lg-5 px-lg-5 " ref={this.section1Ref}>
          <div
            className="container-fluid w3-display-container"
            style={{
              marginTop: "150px",
              marginBottom: "150px",
              minHeight: "600px",
            }}
          >
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="ml-lg-5  slower animated infinite  pulse "
                  src="/jet.png"
                  alt="Code"
                  style={{
                    width: "80%",
                    zIndex: "1",
                  }}
                />
              </div>
              <div className="col-lg-5">
                <div className="text-white mt-4 ">
                  <b className="fontFam0" style={{ fontSize: "70px" }}>
                    WHAT IS $CODE
                  </b>

                  <div
                    className="text-white p-4"
                    style={{
                      fontWeight: "700px",
                      backgroundColor: "#a32992",
                      borderRadius: "30px",
                      fontSize: "26px",
                    }}
                  >
                    Ah, buckle up, fellow Degen! The CODE token is like a
                    rollercoaster ride through a meme-filled wonderland where
                    degens roam free and diamond hands reign supreme! It&apos;s
                    a cosmic concoction of chaos, memes, and moonshots
                    that&apos;ll make you laugh, cry, and maybe even question
                    your sanity. So hop on board, hold onto your hats, and get
                    ready for the craziest crypto adventure of your life!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pe-3 px-3 pe-lg-5 px-lg-5 " ref={this.section2Ref}>
          <div
            className="container-fluid w3-display-container"
            style={{
              backgroundColor: "orange",
              marginTop: "30px",
              minHeight: "600px",
              borderRadius: "20px",
            }}
          >
            <div className="row">
              <div className="col-lg-7" style={{ overflow: "hidden" }}>
                <div className="text-center animatedElementLeft ">
                  <div className="fontFam0" style={{ marginTop: "150px" }}>
                    <b style={{ fontSize: "45px", color: "white" }}>
                      TOKEN SUPPLY:
                    </b>
                  </div>
                  <div>
                    <b style={{ fontSize: "40px", color: "white" }}>
                      55, 000, 000, 000
                    </b>
                  </div>
                  <div
                    className="p-4 mt-4 mb-4"
                    style={{
                      border: "25px solid #ffc150",
                      display: "inline-block",
                      borderRadius: "30px",
                      width: "70%",
                      textAlign: "left",
                      color: "white",
                      fontSize: "25px",
                    }}
                  >
                    <div>No taxes, No Bullshit. It&apos;s That Simple</div>
                    <div className="pt-4">
                      LP tokens locked & burnt! Contract ownership renounced.
                      Top CEX listing confirmed.
                    </div>
                  </div>
                  <p
                    className="d-none d-md-none d-lg-block pt-5"
                    style={{ paddingBottom: "150px" }}
                  >
                    <a
                      href="https://github.com/Audit-Ace/Audits-Smart-Contracts/blob/main/COMMUNITY%20OF%20DEGENS%20Audit%20Report.pdf "
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn  primary-btn  animated slow  slideInBottom"
                    >
                      Audit link
                    </a>
                  </p>
                </div>
              </div>
              <div
                className="col-lg-5 text-center"
                style={{ overflow: "hidden" }}
              >
                <p
                  className="d-none d-md-none d-lg-block"
                  style={{ marginTop: "120px" }}
                ></p>
                <center>
                  <img
                    className=" slow   d-block d-md-none d-lg-none animatedElementRight "
                    src="/code.png"
                    alt="Code"
                    style={{
                      width: "80%",
                    }}
                  />
                  <img
                    className=" slow   d-none d-md-block d-lg-block animatedElementRight "
                    src="/code.png"
                    alt="Code"
                    style={{
                      width: "550px",
                    }}
                  />
                </center>
              </div>
              <div className="col-lg-12 text-center pb-3 pt-3"></div>
            </div>
          </div>
        </div>

        <div className="pe-3 px-3 pe-lg-5 px-lg-5 ">
          <div
            className="container-fluid w3-display-container"
            style={{
              marginTop: "80px",
              marginBottom: "80px",
              minHeight: "600px",
            }}
          >
            <div className="row">
              <div className="col-lg-6">
                <p
                  className="d-none d-md-block d-lg-block"
                  style={{ marginTop: "120px" }}
                ></p>
                <img
                  className="ml-lg-5  slower animated infinite  pulse "
                  src="/favicon.png"
                  alt="Code"
                  style={{
                    width: "80%",
                    zIndex: "1",
                  }}
                />
              </div>
              <div className="col-lg-5 " style={{ overflow: "hidden" }}>
                <div className="text-white mt-4 slow animatedElementUp">
                  <div className="text-center fontFam0">
                    <b style={{ fontSize: "60px" }}>ROAD MAP</b>
                  </div>
                  <div className="text-white  text-center pt-4">
                    <span
                      className="mb-4 fontFam0"
                      style={{
                        padding: "10px 30px",
                        fontWeight: "400px",
                        backgroundColor: "#a32992",
                        borderRadius: "17px",
                        fontSize: "41px",
                        display: "inline-block",
                      }}
                    >
                      PHASE 1 $CODE LAUNCH
                    </span>
                    <span
                      className="mb-4 fontFam0"
                      style={{
                        padding: "10px 30px",
                        fontWeight: "400px",
                        backgroundColor: "#a32992",
                        borderRadius: "17px",
                        fontSize: "38px",
                        display: "inline-block",
                      }}
                    >
                      PHASE 2 VIBE AND HOLD
                    </span>
                    <span
                      className="mb-4 fontFam0"
                      style={{
                        padding: "10px 30px",
                        fontWeight: "400px",
                        backgroundColor: "#a32992",
                        borderRadius: "17px",
                        fontSize: "36px",
                        display: "inline-block",
                      }}
                    >
                      PHASE 3 JEETERS WEEP
                    </span>
                    <span
                      className="mb-4 fontFam0"
                      style={{
                        padding: "10px 30px",
                        fontWeight: "400px",
                        backgroundColor: "#a32992",
                        borderRadius: "17px",
                        fontSize: "28px",
                        display: "inline-block",
                      }}
                    >
                      PHASE 4 DEGENS TAKEOVER
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pe-3 px-3 pe-lg-5 px-lg-5  pb-5">
          <div
            className="container-fluid w3-display-container"
            style={{
              backgroundColor: "orange",
              marginTop: "30px",
              minHeight: "600px",
              borderRadius: "20px",
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <div className=" fontFam0" style={{ marginTop: "50px" }}>
                    <b style={{ fontSize: "60px", color: "white" }}>CONTACT</b>
                    <div className="pt-4 text-center">
                      <a
                        href=" https://twitter.com/codememetoken"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w3-hover-opacity animated slow  slideInUp"
                      >
                        <img
                          className=" slower animated infinite  m-2 pulse "
                          src="/x.png"
                          alt="Code"
                          style={{
                            width: "60px",
                            zIndex: "1",
                          }}
                        />
                      </a>

                      <a
                        aria-current="page"
                        href=" https://t.me/communityofdegens"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w3-hover-opacity animated slow  slideInDown"
                      >
                        <img
                          className=" slower animated infinite  m-2 pulse "
                          src="/tele.png"
                          alt="Code"
                          style={{
                            width: "60px",
                            zIndex: "1",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    className=" pe-3 px-3 pe-lg-5 px-lg-5 "
                    style={{ color: "white", fontSize: "27px" }}
                  >
                    Listen up, fellow degens! $CODE isn&apos;t just your average
                    meme coin - it&apos;s a masterpiece of absurdity, a beacon
                    of hilarity, and a testament to the wild and wonderful world
                    of crypto degeneracy. With no rigid teams or roadmaps to tie
                    us down, we&apos;re free to embrace the chaos and revel in
                    the sheer joy of being part of something utterly
                    inconsequential and entertaining. So let&apos;s raise our
                    meme flags high, dance to the rhythm of volatility, and HODL
                    onto the madness that is $CODE!
                    <div className=" pt-5" style={{ fontSize: "18px" }}>
                      (c) 2024 CODE. All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="AGREEMENT:"
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          content={popupMessage}
        />

        <Footer />
      </div>
    );
  }
}

export default Home;
