import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class ComingSoon extends Component {
  render() {
    return (
      <div className="w3-display-container" style={{ height: "100vh" }}>
        <div className="w3-display-middle  text-center">
          <h1
            className="animated heartBeat infinite slow text-center"
            style={{ fontSize: "60px", color: "orange", fontWeight: "800" }}
          >
            Coming Soon
          </h1>

          <NavLink
            exact
            to="/"
            activeClassName="active"
            className="btn secondary-btn mt-5"
          >
            Back to Home
          </NavLink>
        </div>
      </div>
    );
  }
}

export default ComingSoon;
