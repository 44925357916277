import React, { Component } from "react";
import PropTypes from "prop-types";

class Modal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { content, showModal } = this.props;

    return (
      <div
        className={`modal fade  ${showModal ? "show" : ""}`}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered ">
          <div
            className="modal-content"
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <div
              className="modal-body p-0"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="w3-display-container">
                <div className="w3-display-middle">
                  <div
                    className="spinner-border   text-light"
                    role="status"
                    style={{ zIndex: 0 }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                <p
                  className="text-center"
                  onClick={this.props.toggleModal}
                  style={{ zIndex: 3, position: "relative" }}
                >
                  {content}
                  <button
                    type="button"
                    className="btn btn-warning pt-2 pb-2"
                    style={{ width: "400px", backgroundColor: "#ddbd95" }}
                  >
                    Confirm
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired, // Make sure it's a function and required
  title: PropTypes.string.isRequired, // A required string prop
  showModal: PropTypes.bool.isRequired, // A required string prop
  content: PropTypes.node,
};

export default Modal;
