import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import NoPage from "./pages/404";
import ComingSoon from "./pages/comingsoon";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let BASEURL = "https://cadencepub.com/production/";
    // BASEURL = "http://localhost:5000/development/";
    return (
      <div>
        <Router>
          <Routes>
            <Route index element={<Home BASEURL={BASEURL} />} />
            <Route path="/" element={<Home BASEURL={BASEURL} />} />
            <Route path="/home" element={<Home BASEURL={BASEURL} />} />
            <Route
              path="/comingsoon"
              element={<ComingSoon BASEURL={BASEURL} />}
            />
            <Route path="*" element={<NoPage BASEURL={BASEURL} />} />
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
